@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Rubik, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.left-24 {
  left: 6rem;
}

.top-4 {
  top: 1rem;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.-left-2 {
  left: -.5rem;
}

.right-4 {
  right: 1rem;
}

.right-\[8\.3\%\] {
  right: 8.3%;
}

.-top-8 {
  top: -2rem;
}

.right-6 {
  right: 1.5rem;
}

.-top-4 {
  top: -1rem;
}

.-right-3 {
  right: -.75rem;
}

.right-0 {
  right: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.top-6 {
  top: 1.5rem;
}

.left-0 {
  left: 0;
}

.-top-1 {
  top: -.25rem;
}

.-right-1 {
  right: -.25rem;
}

.-left-10 {
  left: -2.5rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-10 {
  right: -2.5rem;
}

.right-2 {
  right: .5rem;
}

.bottom-2 {
  bottom: .5rem;
}

.left-10 {
  left: 2.5rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.top-1 {
  top: .25rem;
}

.z-\[100\] {
  z-index: 100;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-32 {
  margin-left: 8rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-1 {
  margin-right: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-px {
  margin-bottom: 1px;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-auto {
  margin-right: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-auto {
  aspect-ratio: auto;
}

.h-5 {
  height: 1.25rem;
}

.h-full {
  height: 100%;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-\[70vh\] {
  height: 70vh;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[10\%\] {
  height: 10%;
}

.h-8 {
  height: 2rem;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-96 {
  height: 24rem;
}

.h-28 {
  height: 7rem;
}

.h-\[40px\] {
  height: 40px;
}

.h-screen {
  height: 100vh;
}

.h-1\/2 {
  height: 50%;
}

.h-\[calc\(100vh_-_40px\)\] {
  height: calc(100vh - 40px);
}

.h-\[88\%\] {
  height: 88%;
}

.h-\[12\%\] {
  height: 12%;
}

.h-auto {
  height: auto;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-\[50\%\] {
  height: 50%;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-full {
  max-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-\[34vh\] {
  min-height: 34vh;
}

.w-auto {
  width: auto;
}

.w-5 {
  width: 1.25rem;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-3\/6 {
  width: 50%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-\[30vw\] {
  width: 30vw;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-1\/5 {
  width: 20%;
}

.w-12 {
  width: 3rem;
}

.w-64 {
  width: 16rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[80\%\] {
  width: 80%;
}

.w-2 {
  width: .5rem;
}

.w-4 {
  width: 1rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-24 {
  width: 6rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-4\/5 {
  width: 80%;
}

.w-3\/4 {
  width: 75%;
}

.w-16 {
  width: 4rem;
}

.w-8 {
  width: 2rem;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[70\] {
  max-width: 70px;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-\[920px\] {
  max-width: 920px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[540px\] {
  max-width: 540px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.flex-none {
  flex: none;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-1 {
  gap: .25rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-none {
  border-style: none;
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-200\/80 {
  border-color: #e5e5e5cc;
}

.border-blue-400\/20 {
  border-color: #60a5fa33;
}

.border-hospitaliaTeal-base {
  --tw-border-opacity: 1;
  border-color: rgb(48 163 174 / var(--tw-border-opacity));
}

.border-neutral-200\/90 {
  border-color: #e5e5e5e6;
}

.border-hospitaliaGold-base {
  --tw-border-opacity: 1;
  border-color: rgb(251 181 73 / var(--tw-border-opacity));
}

.border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

.border-neutral-200\/70 {
  border-color: #e5e5e5b3;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-\[\#ddd\] {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-\[\#eee\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.bg-hospitaliaTeal-light {
  --tw-bg-opacity: 1;
  background-color: rgb(65 180 191 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-hospitaliaGold-base {
  --tw-bg-opacity: 1;
  background-color: rgb(251 181 73 / var(--tw-bg-opacity));
}

.bg-\[\#1e65af\] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 101 175 / var(--tw-bg-opacity));
}

.bg-hospitaliaTeal-base {
  --tw-bg-opacity: 1;
  background-color: rgb(48 163 174 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-hospitaliaBlue-base {
  --tw-bg-opacity: 1;
  background-color: rgb(0 40 85 / var(--tw-bg-opacity));
}

.bg-purple-200\/80 {
  background-color: #e9d5ffcc;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-teal-200\/50 {
  background-color: #99f6e480;
}

.bg-neutral-200\/70 {
  background-color: #e5e5e5b3;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-to: #1e3a8a00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-200 {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-to: #bfdbfe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-teal-100 {
  --tw-gradient-to: #ccfbf1;
}

.to-blue-50 {
  --tw-gradient-to: #eff6ff;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-top {
  background-position: top;
}

.bg-center {
  background-position: center;
}

.bg-bottom {
  background-position: bottom;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[4px\] {
  padding: 4px;
}

.p-\[2px\] {
  padding: 2px;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.indent-4 {
  text-indent: 1rem;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-xxs {
  font-size: .6rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-\[2rem\] {
  font-size: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-tight {
  line-height: 1.25;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-\[\#979797\] {
  --tw-text-opacity: 1;
  color: rgb(151 151 151 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-hospitaliaBlue-base {
  --tw-text-opacity: 1;
  color: rgb(0 40 85 / var(--tw-text-opacity));
}

.text-hospitaliaTeal-base {
  --tw-text-opacity: 1;
  color: rgb(48 163 174 / var(--tw-text-opacity));
}

.text-hospitaliaTeal-dark {
  --tw-text-opacity: 1;
  color: rgb(16 161 171 / var(--tw-text-opacity));
}

.text-hospitaliaGold-base {
  --tw-text-opacity: 1;
  color: rgb(251 181 73 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-blue-700\/80 {
  color: #1d4ed8cc;
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-teal-700\/60 {
  color: #0f766e99;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-hospitaliaTeal-light {
  text-decoration-color: #41b4bf;
}

.opacity-20 {
  opacity: .2;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[rgba\(0\,0\,0\,0\.1\)_0px_0px_10px_0px\] {
  --tw-shadow: #0000001a 0px 0px 10px 0px;
  --tw-shadow-colored: 0px 0px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-100 {
  --tw-shadow-color: #ccfbf1;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-blue-100 {
  outline-color: #dbeafe;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[0\.01\] {
  --tw-blur: blur(.01);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-100 {
  --tw-saturate: saturate(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-0 {
  --tw-saturate: saturate(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

* {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  overflow-x: hidden;
}

._nodrag, img, svg {
  -webkit-user-drag: none;
  user-drag: none;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 10px;
  height: 16px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #edf2f7;
  border-radius: 100vh;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  cursor: grab;
  background: #dbe5e0;
  border: 2px solid #edf2f7;
  border-radius: 100vh;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  cursor: grab;
  background: #cad4d0;
}

.input-field {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: transparent;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: transparent;
  border-radius: .25rem;
  outline: 2px solid #0000;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-field:focus, .input-field:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 1px;
  --tw-ring-offset-color: #fff;
}

.input-field:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
}

.input-field:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  opacity: .8;
}

.before\:mr-1:before {
  content: var(--tw-content);
  margin-right: .25rem;
}

.before\:h-\[2px\]:before {
  content: var(--tw-content);
  height: 2px;
}

.before\:flex-grow:before {
  content: var(--tw-content);
  flex-grow: 1;
}

.before\:bg-\[\#dcdcdc\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(220 220 220 / var(--tw-bg-opacity));
}

.after\:ml-1:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.after\:h-\[2px\]:after {
  content: var(--tw-content);
  height: 2px;
}

.after\:flex-grow:after {
  content: var(--tw-content);
  flex-grow: 1;
}

.after\:bg-\[\#dcdcdc\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(220 220 220 / var(--tw-bg-opacity));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.hover\:border-hospitaliaGold-base:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 181 73 / var(--tw-border-opacity));
}

.hover\:border-teal-600\/20:hover {
  border-color: #0d948833;
}

.hover\:bg-hospitaliaGold-base:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 181 73 / var(--tw-bg-opacity));
}

.hover\:bg-hospitaliaTeal-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(65 180 191 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-70:hover {
  --tw-bg-opacity: .7;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-\[rgba\(0\,0\,0\,0\.08\)_0px_0px_10px_0px\]:hover {
  --tw-shadow: #00000014 0px 0px 10px 0px;
  --tw-shadow-colored: 0px 0px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:bg-hospitaliaTeal-dark:active {
  --tw-bg-opacity: 1;
  background-color: rgb(16 161 171 / var(--tw-bg-opacity));
}

.disabled\:cursor-default:disabled {
  cursor: default;
}

.disabled\:bg-neutral-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.disabled\:bg-neutral-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.disabled\:shadow-none:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:active .group-active\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:active .group-active\:duration-200 {
  transition-duration: .2s;
}

@media (min-width: 640px) {
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }
}

@media (min-width: 768px) {
  .md\:left-32 {
    left: 8rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:left-40 {
    left: 10rem;
  }

  .lg\:right-5 {
    right: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-\[60px\] {
    height: 60px;
  }

  .lg\:h-\[calc\(100vh_-_60px\)\] {
    height: calc(100vh - 60px);
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-\[6px\] {
    padding: 6px;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:pt-1 {
    padding-top: .25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 1280px) {
  .xl\:left-48 {
    left: 12rem;
  }

  .xl\:right-6 {
    right: 1.5rem;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:mb-3 {
    margin-bottom: .75rem;
  }

  .xl\:mt-1 {
    margin-top: .25rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:mt-3 {
    margin-top: .75rem;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:h-7 {
    height: 1.75rem;
  }

  .xl\:h-\[90px\] {
    height: 90px;
  }

  .xl\:h-\[calc\(100vh_-_100px\)\] {
    height: calc(100vh - 100px);
  }

  .xl\:h-24 {
    height: 6rem;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-28 {
    height: 7rem;
  }

  .xl\:h-10 {
    height: 2.5rem;
  }

  .xl\:w-7 {
    width: 1.75rem;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-16 {
    width: 4rem;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-24 {
    width: 6rem;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-28 {
    width: 7rem;
  }

  .xl\:w-10 {
    width: 2.5rem;
  }

  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:gap-3 {
    gap: .75rem;
  }

  .xl\:gap-2 {
    gap: .5rem;
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .xl\:rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:border-4 {
    border-width: 4px;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:p-7 {
    padding: 1.75rem;
  }

  .xl\:p-\[12px\] {
    padding: 12px;
  }

  .xl\:p-\[4px\] {
    padding: 4px;
  }

  .xl\:p-5 {
    padding: 1.25rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  @media (min-width: 1280px) {
    .xl\:xl\:text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:left-64 {
    left: 16rem;
  }

  .\32 xl\:left-1 {
    left: .25rem;
  }

  .\32 xl\:left-6 {
    left: 1.5rem;
  }

  .\32 xl\:right-7 {
    right: 1.75rem;
  }

  .\32 xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .\32 xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .\32 xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .\32 xl\:mt-8 {
    margin-top: 2rem;
  }

  .\32 xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .\32 xl\:mt-20 {
    margin-top: 5rem;
  }

  .\32 xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .\32 xl\:mt-4 {
    margin-top: 1rem;
  }

  .\32 xl\:mt-16 {
    margin-top: 4rem;
  }

  .\32 xl\:mb-3 {
    margin-bottom: .75rem;
  }

  .\32 xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .\32 xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .\32 xl\:mt-12 {
    margin-top: 3rem;
  }

  .\32 xl\:h-8 {
    height: 2rem;
  }

  .\32 xl\:h-\[100px\] {
    height: 100px;
  }

  .\32 xl\:h-20 {
    height: 5rem;
  }

  .\32 xl\:h-32 {
    height: 8rem;
  }

  .\32 xl\:h-24 {
    height: 6rem;
  }

  .\32 xl\:h-\[7\.5rem\] {
    height: 7.5rem;
  }

  .\32 xl\:h-12 {
    height: 3rem;
  }

  .\32 xl\:w-8 {
    width: 2rem;
  }

  .\32 xl\:w-1\/3 {
    width: 33.3333%;
  }

  .\32 xl\:w-24 {
    width: 6rem;
  }

  .\32 xl\:w-1\/2 {
    width: 50%;
  }

  .\32 xl\:w-\[7\.5rem\] {
    width: 7.5rem;
  }

  .\32 xl\:w-2\/3 {
    width: 66.6667%;
  }

  .\32 xl\:w-12 {
    width: 3rem;
  }

  .\32 xl\:gap-8 {
    gap: 2rem;
  }

  .\32 xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .\32 xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .\32 xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .\32 xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .\32 xl\:border-4 {
    border-width: 4px;
  }

  .\32 xl\:p-4 {
    padding: 1rem;
  }

  .\32 xl\:p-8 {
    padding: 2rem;
  }

  .\32 xl\:p-\[12px\] {
    padding: 12px;
  }

  .\32 xl\:p-\[6px\] {
    padding: 6px;
  }

  .\32 xl\:p-6 {
    padding: 1.5rem;
  }

  .\32 xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .\32 xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .\32 xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .\32 xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .\32 xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .\32 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .\32 xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .\32 xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .\32 xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .\32 xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .\32 xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .\32 xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .\32 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .\32 xl\:leading-tight {
    line-height: 1.25;
  }

  .\32 xl\:leading-none {
    line-height: 1;
  }
}

/*# sourceMappingURL=index.6fd8cb98.css.map */
